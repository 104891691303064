.containerTableListarClientes {
  width: calc(100% - 20px);
  background-color: var(--background-primary);
  border-radius: 5px;
  padding: 10px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

@media (max-width: 550px) {
  .containerTableListarClientes {
    height: calc(100vh - 142px);
  }
}

.containerTableListarClientes table {
  width: 100%;
  border-collapse: collapse;
}

.containerTableListarClientes table th, .containerTableListarClientes table td {
  text-align: left;
  padding: 10px;
  transition: all 0s !important;
}

.containerTableListarClientes tbody tr:hover:not(:last-child) {
  background-color: var(--background-secondary);
  cursor: pointer;
}

.containerTableListarClientes table thead {
  position: sticky;
  background-color: var(--background-primary);
}

.containerPageOptionsListarClientes {
  display: flex;
  justify-content: flex-start;
}

.containerPageOptionsListarClientes button {
  border: 1px solid var(--border-color);
  background-color: var(--background-primary);
  color: var(--text-primary);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none;
  font-size: 16px;
  transition: all .2s;
  cursor: pointer;
}

.containerPageOptionsListarClientes button:hover {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.containerPageOptionsListarClientes button:last-child {
  border-right: 1px solid var(--border-color);
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.containerPageOptionsListarClientes button:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.buttonSelectedPageListarClientes {
  background-color: var(--background-secondary) !important;
  color: var(--text-secondary) !important;
}