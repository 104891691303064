.containerCard{
  /* padding: 10px; */
  background-color: var(--background-primary);
  border-radius: 3px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  height: fit-content;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 70%;
  width: 70%;
}
.containerCard >div:not(.wrap-collabsible){
  display: flex;
  /* flex-direction: row; */
  padding: 10px 0px;
}