input:disabled, textarea:disabled {
  background-color: var(--border-color) !important;

}

textarea {
  resize: none;
}

/*? INPUT TEXT || INPUT CURRENCY  */
.InputTextContainer, .InputCurrencyContainer {
  margin: 5px;
}

#InputTextSpan {
  white-space: nowrap;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: 'Roboto';
  color: var(--text-primary);
  margin-top: 2px;
}

#InputTextInput {
  width: calc(100% - 28px);
  outline: none;
  border-radius: 3px;
  height: 40px;
  font-size: 17px;
  padding: 0 14px;
  border: 1px solid var(--border-color);
  transition: all .2s;
  background-color: transparent;
  color: var(--text-primary);
}
#InputTextInput + span{
  display: none;
}
#InputTextInput:valid {
  border-color: var(--border-color) !important;
}

#InputTextInput:valid + span {
  display: none !important;
}

#InputTextInput:focus {
  border: 1px solid var(--blue) !important;
}

/*? INPUT CURRENCY  */
.InputCurrencyContainer {
  position: relative;
}

.InputCurrencyContainer input {
  width: calc(100% - 50px) !important;
  text-align: right;
}

.InputCurrencyContainer[tipo='prefix'] input {
  padding-left: 35px !important;
}

.InputCurrencyContainer[tipo='sufix'] input {
  padding-right: 35px !important;
}

#prefix, #sufix {
  position: absolute;
  font-size: 16px;
  color: var(--text-primary);
  font-weight: 700;
  top: 37px;
}

#prefix[span_=''], #sufix[span_=''] {
  top: 11px !important;
}

#prefix {
  left: 10px
}

#sufix {
  right: 10px;
}

/*? SELECT INPUT */
.react-select__option--is-focused:not(.react-select__option--is-selected) {
  background-color: var(--border-color);
}

.hiddenReactInputSelect {
  display: none;
  /* background-color: transparent; */
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

#hiddenReactInputSelectContainer {
  z-index: 10;
  margin: 0px;
  /* margin-top: -40px; */
}

#InputTextInput+#borderInputSelect {
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--border-color);
  height: 40px;
}

#InputTextInput:disabled+#borderInputSelect {
  background-color: var(--border-color);
}

#InputTextInput:focus+#borderInputSelect {
  border: 1px solid var(--blue);
}

#InputTextInput:valid+#borderInputSelect+span {
  display: none;
}

#InputTextInput:valid+#borderInputSelect {
  border: 1px solid var(--border-color) !important;
}

/* ? BUTTON */
.buttonLoadingContainer {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  background-color: var(--blue);
  border-radius: 3px;
  color: white;
  border: none;
  height: 30px;
  padding: 20px 10px;
  margin: 0px 5px;
  max-width: 300px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  transition: all .2s;
}

.buttonContainer:disabled {
  opacity: 0.9;
  background-color: var(--border-color);
  cursor: default;
}

.buttonContainer:active {
  opacity: 0.8;
}

.buttonContainer:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.buttonContainer svg {
  margin: 0px 5px;
}

.buttonContainer .noMargin svg {
  margin: 0px 0px !important;
}

/* ?BUTTON OUTLINE CONTAINER */

.buttonOutlineContainer {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  border: 1px solid var(--blue);
  background-color: transparent;
  border-radius: 3px;
  color: var(--blue);
  height: 30px;
  padding: 20px 10px;
  margin: 0px 5px;
  max-width: 300px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  transition: all .2s;
}

.buttonOutlineContainer:disabled {
  opacity: 0.9;
  border-color: var(--border-color);
  color: var(--border-color);
  cursor: default;
}

.buttonOutlineContainer:active {
  opacity: 0.8;
}

.buttonOutlineContainer:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.buttonOutlineContainer svg {
  margin: 0px 5px;
}

.buttonOutlineContainer .noMargin svg {
  margin: 0px 0px !important;
}




/* ?BUTTON TEXT */



.buttonTextContainer {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  background-color: transparent;
  border-radius: 3px;
  color: #f54545;
  border: none;
  height: 30px;
  padding: 20px 10px;
  margin: 0px 5px;
  max-width: 300px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 800;
  transition: all .2s;
}

.buttonTextContainer:disabled {
  opacity: 0.9;
  color: var(--border-color);
  cursor: default;
}

.buttonTextContainer:active {
  opacity: 0.8;
}

.buttonTextContainer:hover {
  opacity: 0.6;
}






/* ? CHECKBOX */
/* The container */
.containerCheckBox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin: 0px 10px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkBoxCheckMark {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.containerCheckBox:hover input~.checkBoxCheckMark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckBox input:checked~.checkBoxCheckMark {
  background-color: #2196F3;
}

/* Create the checkBoxCheckMark/indicator (hidden when not checked) */
.checkBoxCheckMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkBoxCheckMark when checked */
.containerCheckBox input:checked~.checkBoxCheckMark:after {
  display: block;
}

/* Style the checkBoxCheckMark/indicator */
.containerCheckBox .checkBoxCheckMark:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/* ? TOGGLE */

.toggle {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 4px;
  background-color: #CCC;
  border-radius: 30px;
  border: 2px solid #CCC;
  padding: 8px;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #FFFFFF;
  top: 1px;
  left: 1px;
  bottom: 1px;
  transition: all 0.5s;
  margin-bottom: 5px;
}

/* Checkbox cheked effect */
.checkbox:checked+.toggle::after {
  left: 22px;
}

/* Checkbox cheked toggle label bg color */
.checkbox:checked+.toggle {
  background-color: var(--blue);
  border: 2px solid var(--blue);
}

/* Checkbox vanished */
.checkbox {
  display: none;
}




.SelectMenuContainer {
  position: absolute;
  width: calc(100%);
  background-color: var(--background-primary);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  box-shadow: 0px 7px 15px rgb(0 0 0 / 0.4);

}

#SelectOptionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}

#SelectOptionContainer div {
  display: flex;
  flex-direction: column;
}

#SelectOptionContainer div p:nth-child(1) {
  font-weight: 500;
}

#SelectOptionContainer div p:nth-child(2) {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: var(--text-secondary);
}

#SelectOptionContainer:hover {
  background-color: var(--background-secondary);
}

.focus, .focusDefault {
  background-color: var(--focus);
}

#InputSelect:not(:focus-within) .SelectMenuContainer {
  display: none;
}

.SelectMenuLoadingContainer, .SelectMenuCreateContainer {
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  align-items: center;
  padding: 10px;
  background-color: var(--background-primary);
  box-shadow: 0px 7px 15px rgb(0 0 0 / 0.4);
  position: absolute;
  z-index: 2;
  width: calc((100% - 20px));
}

.SelectMenuLoadingContainer {
  justify-content: center;
}

.SelectMenuCreateContainer:hover {
  cursor: pointer;
  background-color: var(--focus);
}

.InputSelectIcon {
  position: relative;
  float: right;
  display: flex;
  margin-top: -36px;
  right: 0px;
  color: var(--text-primary);
  border-left: 1px solid var(--border-color);
  padding: 5px;
}

.InputSelectIcon[span_=''] {
  top: 8px !important;
}

.InputTextInputSelectCreate {
  width: calc(100% - 41px) !important;
  padding: 0px 27px 0px 14px !important;
}