.containerNovoRegistro {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(250, 250, 250);
}
.vermaisText{
  padding: 5px;
  font-size: 14px;
  color: var(--border-color);
  cursor: pointer;
  transition: all .2s;
}
.vermaisText:hover{
  opacity: 0.6;
}
.containerInputs {
  display: flex;
  width: 400px;
  flex-direction: column;
  border: 1px solid rgb(219, 219, 219);
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  height: fit-content;
  margin-top: 30px;
}

td {
  font-weight: 100 !important;
  cursor: default !important;
  font-size: 14px;
  user-select: none;
}

td, th {
  border-bottom: 1px solid rgb(219, 219, 219);
}

td svg {
  padding: 0px 5px;
  font-size: 20px;
  color: var(--border-color);
  transition: all .2s;
}

td svg:hover {
  cursor: pointer;
  opacity: 0.6;
}
.modalDelete{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.backgroundBlack{
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0.8;
  width: 100%;
}
.containerItem{
  position: fixed;
  top: 200px;
  z-index: 11;
  width: 300px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8)
}
.containerItem h3{
  margin: 20px;
}
.containerItem div{
  height: 100%;
  margin: 10px;
  display: flex;
  justify-content: flex-end;
}
.ofxSelected td{
  background-color: lightblue;
}
.ofxDontSelected td{
  /* background-color: lightgray; */
}