.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.modal .containerCard {
  /* max-height: 85%; */
  overflow: auto;
  position: absolute;
  top: 5%;
  left: 50%;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

}

.iconClose {
  float: right;
  margin: 10px 5px 0 0;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 3;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: white;
}

input[id*="modal_"] {
  position: fixed;
  left: -9999px;
  top: 50%;
  opacity: 0;
}

input[id*="modal_"]:checked+div.modal {
  opacity: 1;
  visibility: visible;
}

input[id*="modal_"]:checked+div.modal .containerCard {
  opacity: 1;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.container-ModalFullpage {
  background-color: var(--background-secondary);
  position: fixed;
  z-index: 10;
  top: 31px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: calc(100vh - 31px);
  width: calc(100vw - 40px);
  animation: container-ModalFullPage 0.3s forwards 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@keyframes container-ModalFullPage {
  0%{
    top: 100vw;
  }
  100%{
    top: 31px;
  }
}
.onGoBack-ModalFullPage{
  cursor: pointer;
  transition: all .2s;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  width: 80px;
  font-weight: 100;
  align-self: flex-start;
}
.onGoBack-ModalFullPage:hover{
  opacity: 0.8;
}