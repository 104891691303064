:root {
  --background-primary: white;
  --background-secondary: #eee;
  --text-primary: #363636;
  --text-secondary: #5a5a5a;
  --focus: #dfdddd;
  --accent: #f54545;
  --accent-rgb: 245, 69, 69;
  --border-color: #b4b4b4;
  --red: #d63031;
  --blue: #0984e3;
  --green: #00b894;
}

[data-theme='dark'] {
  --background-primary: #363636;
  --background-secondary: #262626;
  --focus: #1d1d1d;
  --text-primary: white;
  --text-secondary: #dddddd;
  --accent: #f25c5c;
  --accent-rgb: 242, 92, 92;
  --border-color: #4e4e4e;
}

* {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Roboto';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, a, b {
  padding: 0;
  margin: 0;
  color: var(--text-primary);
  font-family: 'Roboto';
  text-decoration: none;
}

input {
  font-family: 'Roboto';
}

span {
  font-family: 'Roboto';
  color: var(--accent);
  font-size: 12px;
  margin-left: 3px;
  /* position: absolute; */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

* {
  transition: color .2s, background-color .2s;
}


/* CUSTOM SCROLL BAR */
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */

::-webkit-scrollbar {
  background-color: var(--background-secondary);
  width: 8px;
}

/* background of the scrollbar except button or resizer */

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}



/* TABELA GLOBAL */

.tableGlobal {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  margin: 10px 0px;
}

.tableGlobal td, .tableGlobal th{
  text-align: left;
  padding: 8px;
}


.tableGlobal td:last-child, .tableGlobal th:last-child {
  text-align: right;
  padding: 8px;
}

.tableGlobal tr:hover td {
  background-color: var(--background-secondary);
  cursor: pointer;
}

.tableGlobal tbody:last-child th {
  border: none;
}