.containerGrafico {
  display: flex;
  width: 80%;
  border-bottom: 1px solid #ccc;
}

.containerIndicadores {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 100px;
  background-color: rgb(250, 250, 250);
}

.containerIndicadores h2 {
  width: 75%;
  margin-top: 20px;
}

.containerHeader {
  margin: 15px;
  width: 75%;
}

.containerHeader h2 {
  margin: 10px 0px;
}

.containerTable {
  width: 40%;
  margin-top: 30px
}
.tableExcel th{
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 2px solid black;
  background-color: #ccc;
}
.tableExcel td:not(empty) {
  background-color: #eee;
}
.tableExcel td:empty{
  background-color: white;
}

@media (max-width: 750px) {
  .containerGrafico {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .containerTable{
    width: 100%;
    margin: 0px;
  }
  .containerHeader{
    width: 97%;
    justify-content: center;
  }
  .containerIndicadores h2{
    width: 97%;
  }
}