.containerNavigation{
  position: fixed;
  height: 65px;
  width: 100%;
  border-top: 1px solid rgb(219,219,219);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom:0px
}
.itemNavigation{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
  transition: all .2s;
  font-weight: 100;
  opacity: 0.8;
}
.itemNavigation:hover{
  opacity: .6;
}
#itemSelected{
  font-weight: bold;
  opacity: 1;
}