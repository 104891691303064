.containerLogin{
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(250,250,250);
}
.containerInputsLogin{
  display: flex;
  justify-content: center;
  align-self: center;
  width: 400px;
  flex-direction: row;
  border: 1px solid rgb(219,219,219);
  padding: 10px;
  border-radius: 4px;
  background-color: white;
}