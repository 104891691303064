.blackScreen{
  background-color: black;
  opacity: 0.6;
  width: 100vw;
  height: calc(100vh - 40px);
  position: absolute;
  z-index: 100;
  top: -10px;
}
.lateralBarContainer{
  top:0;
  position: fixed;
  width: 350px;
  background-color: white;
  height: 100vh;
  z-index: 100;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.8 ); 
  transition: all .2s;
}
